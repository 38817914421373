.pump-info
  margin-right: 28px
  background-color: var(--light-blue)
  padding: 10px 14px
  border-radius: 10px

  &__header
    display: flex
    justify-content: flex-end

  &__urls
    margin-top: 12px
